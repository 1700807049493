@import 'styles/variables';

.holidays-requests-document {
  display: flex;
  align-items: center;

  .missing {
    color: $danger-red;
  }

  .pdf-data-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    p {
      width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .pdf-icon-box {
      display: flex;
    }
  }

  #download-file-icon {
    color: $tertiary-color;
  }

  #delete-file-icon {
    color: $bright-red;
  }
}
