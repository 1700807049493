.year-filter {
  padding-left: 32px;
  padding-bottom: 15px;
  .select {
    .MuiInputLabel-root,
    .MuiInputBase-input {
      color: white !important;
    }
  }

  @media screen and (max-width: 850px) {
    .MuiInputLabel-root {
      margin-bottom: 7px;
      position: relative;
      left: 35%;
    }
  }
}

.bug {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}
