@import 'src/styles/variables';

.interview-details {
  .reviewers {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid $light-grey;

    .reviewers-list {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
    }

    .reviewer-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.25rem 0.5rem;
      border-radius: 4px;
      cursor: pointer;

      .reviewer-name {
        font-size: 0.85rem;
        color: $mid-dark-grey;
      }

      .reviewer-action {
        color: $mid-dark-grey;
        transition: color 0.2s ease;
      }

      &.has-answered {
        .reviewer-action {
          color: $main-blue;
        }
      }

      &:hover {
        .reviewer-name {
          color: $dark-grey;
        }
      }
    }
  }
}
