.settings {
  .row {
    display: flex;
    flex-direction: row;
  }

  .btn-box {
    flex-direction: row;
  }
}

@media screen and (max-width: 650px) {
  .settings .btn-box {
    flex-direction: column;
  }
}
