.data {
  .autocomplete {
    width: 190px;
  }
}

.list-material tr {
  th:first-child {
    padding-left: 40px;
  }

  td:first-child {
    padding-left: 40px;
  }
}

@media screen and (max-width: 650px) {
  .data {
    .autocomplete {
      width: 100%;
    }
  }
}
