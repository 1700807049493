.expenses {
  .myexpenses-total {
    margin-top: 0;
    text-align: end;

    @media screen and (max-width: 550px) {
      text-align: start;
    }
  }

  .expenses-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: last baseline;

    .expenses-filters {
      display: flex;

      .year-filter {
        padding-left: 0;
        padding-right: 25px;

        .select {
          .MuiInputLabel-root,
          .MuiInputBase-input,
          .MuiInputBase-root {
            color: black !important;

            @media screen and (max-width: 550px) {
              width: auto;
            }
          }
        }
      }
    }
  }

  .expenses-body {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  &.no-padding-top {
    padding-top: 0;
  }
}
