@import '../../styles/variables';
@import '../../styles/fonts';

.showMenu {
  .warning-container {
    position: absolute;
    right: 0;
    top: 8px;

    .MuiSvgIcon-root {
      color: $bright-red !important;
    }
  }
}

.navBar {
  .mobile-menu-height {
    height: 40px;
  }
  .mobile-menu-height-with-publication {
    height: 60px;
  }
  .mobile-navbar {
    display: none;
  }

  .active {
    background-color: $secondary-blue;
    border-left: 3px solid white;
  }
  .listItem {
    color: white;
    border-left: 3px solid transparent;

    &:hover {
      background-color: $secondary-blue;
      border-left: 3px solid white;
    }

    img {
      margin-left: -8px;
    }

    &.subMenuItem {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .divider {
    border-bottom: 1px solid rgb(167, 167, 167);
  }

  .drawerHeader {
    background-color: $secondary-blue;
    color: white;
    height: 65px;
    display: flex;
    justify-content: center;
  }

  #nav-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 55px;
    height: 65px;
  }

  .navOpen {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 20px;
    width: 100%;
  }

  .navCLose {
    text-align: center;
    padding-top: 12px;
  }

  .menu-category {
    color: white;
    padding: 10px;
    margin: 0;
    font-style: italic;
    font-weight: 600;
  }
  .submenu-open {
    transform: rotate(180deg);
  }
}

// Permet de cibler une classe de MUI où on souhaite surcharger le style
.navBar {
  .MuiPaper-root {
    background-color: $main-blue;
    z-index: 2;
  }
  .MuiTypography-root {
    font-family: $primary-font;
  }
  .MuiListItem-root:hover .MuiTypography-root {
    font-weight: 700;
  }
  .MuiTypography-root {
    font-size: 14px;
  }
  .MuiListItem-root {
    padding: 6px 0;
  }
  .MuiDrawer-docked .MuiDrawer-paper {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  .MuiSvgIcon-root {
    color: white;
    font-size: 1.3rem;
  }
  .MuiListItemIcon-root {
    min-width: 36px;

    .profil-img {
      min-width: 45px;
    }
  }
}

@media screen and (max-width: 550px) {
  .navBar {
    .warning-container {
      font-size: 20px;
      position: absolute;
      right: 0;
      top: 0px;
    }

    .MuiPaper-root {
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
    }

    .mobile-navbar {
      display: block;
      .MuiSvgIcon-root {
        color: $main-blue;
      }
    }
    .showMenu .mobile-navbar {
      display: none;
    }

    .showMenu .MuiPaper-root {
      transform: translateX(0%);
    }
  }
}
