.buttons-container {
  display: flex;
  column-gap: 15px;

  #delete-icon,
  #end-icon {
    cursor: pointer;
    color: $danger-red;
  }

  #edit-icon,
  #add-icon {
    cursor: pointer;
    color: $dark-grey;
  }
}

#info-icon {
  cursor: pointer;
  color: $dark-grey;
}

#euro-icon {
  cursor: pointer;
  color: $yellow;
}

#settings-icon {
  cursor: pointer;
  color: $dark-grey;
}

#arrow {
  cursor: pointer;
  color: $dark-grey;
}
