.box-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 28px;
  min-width: 175px;
  height: 118px;
  background-color: white;
  border-radius: 10px;
  flex: 1;

  .box-title {
    font-weight: 600;
    font-size: 12px;
  }

  .box-number {
    font-weight: 700;
    font-size: 50px;
    margin: 0;
  }
}

.loading {
  .box-title,
  .box-number {
    color: transparent;
    background: $light-grey; /* Utilisez ici les couleurs de votre dégradé */
  }
}

/* Animation de chargement */
@media screen and (max-width: 1100px) {
  .box-container {
    margin-bottom: 20px;
  }
}
