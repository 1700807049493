.no-group-found {
  text-align: center;
  padding-top: 40px;
}

.steamdej-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
}
.box-steamdej-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 370px;
  padding: 18px 18px 30px;
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  .box-steamdej-title {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    text-align: center;
    margin-top: 15px;

    .firstName {
      font-weight: 100;
    }

    .lastName,
    .firstName {
      color: $secondary-blue;
    }
  }

  .card-group-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 126px;
    align-content: center;
    column-gap: 15px;

    .group-collab-name {
      text-transform: uppercase;
      font-size: 13px;
      margin-top: 25px;
    }
  }

  .lastName {
    font-weight: 900;
  }

  .btn-container {
    margin-top: 30px;
  }

  .btn-newCollabs:hover {
    .gradient {
      color: white !important;
    }
  }

  .btn-title {
    font-weight: 900;
  }

  .gradient {
    background: linear-gradient(to right, $main-blue 20%, $tertiary-color 70%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
}

.gradient {
  background: linear-gradient(to bottom, $card-green, white);
}

.loading {
  .box-title,
  .box-number {
    color: transparent;
    background: $light-grey;
  }
}

@media screen and (max-width: 850px) {
  .box-steamdej-container {
    width: 300px;
  }
}

@media screen and (max-width: 550px) {
  .steamdej-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
}
