@import 'src/styles/variables';

.select {
  //changer la couleur du label
  .MuiInputBase-input,
  .MuiSelect-icon {
    color: $mid-dark-grey;
  }

  .MuiInputLabel-formControl {
    color: black;
  }

  .MuiButtonBase-root {
    .MuiSvgIcon-root {
      font-size: 20px;
    }
  }
}

.customSelectClear {
  display: flex;
  align-items: center;
}

.select-placeholder {
  color: $mid-grey;
}