.subForm-title {
  font-size: 16px;
  font-weight: 500;
  color: $main-blue;
  margin: 0;
}

.dash {
  margin: 10px 0 25px 0;
  border-bottom: 2px solid $main-blue;
  width: 80%;
}
