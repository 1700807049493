.tabs {
  margin-top: 30px;
  margin-bottom: 40px;

  & .MuiTabs-indicator {
    background: linear-gradient(90deg, $main-blue 20%, $tertiary-color 70%);
    height: 5px;
    border-radius: 15px;
  }
  & .MuiTab-root {
    color: rgb(0, 0, 0, 0.75);
    font-family: 'Montserrat';
    font-weight: bold;
  }
  & .Mui-selected {
    color: black !important;
    font-weight: bold;
  }
  & .MuiButtonBase-root {
    border-right: lightgrey solid 1px;
  }
}
