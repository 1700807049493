.box-container-myexpense {
  background-color: white;
  border-radius: 10px;
  max-width: 524px;
  min-width: 450px;

  .myexpense-new-title {
    display: flex;
    align-items: center;

    .left,
    .right {
      flex: 1;
    }

    .right {
      text-align: end;

      svg {
        vertical-align: middle;
      }
    }
  }
  .myexpense-title {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    padding: 30px;

    @media screen and (max-width: 1036px) {
      padding: 10px;
      font-size: 15px !important;
    }

    .icon-close {
      vertical-align: top;
      float: right;
    }
  }

  @media screen and (max-width: 1036px) {
    overflow-y: scroll;
  }

  .myexpense-detail-accordeon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    color: $secondary-blue;
  }
}

.myexpenses-total {
  font-size: 22px;
  margin-top: 30px;

  span {
    color: $secondary-blue;
    font-weight: bold;
    font-size: 18px;
  }
}

.myexpenses-body {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  height: 100%;

  .myexpenses-table {
    width: 50%;
    min-width: auto;

    @media screen and (max-width: 1036px) {
      width: 100%;
    }

    tbody {
      width: 100%;
      display: table;
    }

    .myexpense-content {
      width: 100%;
    }

    tr {
      height: 50px;
      width: 100%;
      display: table;
    }

    .MuiTableRow-root:hover {
      cursor: pointer;
    }

    .MuiTableCell-root {
      font-family: 'Montserrat', sans-serif !important;
      padding: 0;
      width: 25% !important;
      min-width: auto;

      &.user-name {
        width: 45% !important;

        @media screen and (max-width: 1036px) {
          width: 25% !important;
        }

        .d-flex {
          justify-content: flex-start;
        }
      }
    }

    .status-badge {
      width: 40% !important;
    }

    .current-month {
      font-weight: 800;
      color: $secondary-blue;
    }

    .arrow-end-icon {
      text-align: right;
      min-width: 40px;
    }

    #arrow-right,
    #arrow-down {
      color: $main-blue;
      font-size: 35px;
    }

    .empty-icon {
      height: 40px;
    }

    .total-amount {
      text-align: center;
    }
  }

  img {
    width: 200px;
  }

  .divider {
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid lightgrey;
  }

  .form-error {
    text-align: center;
  }

  .myexpense-title {
    font-weight: bold;
    font-size: 20px;
  }

  .myexpense-buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 20px;

    .btn-newCollabs {
      padding: 10px 10px;
      margin-right: 8px;
      color: black;
      border-color: black;

      &:hover {
        color: white;
        border-color: $tertiary-color;
        background: $tertiary-color;
      }
    }

    .select {
      border-color: black;

      #subtypes {
        padding: 22.5px 14px;
        font-size: 15px;
      }

      .MuiOutlinedInput-notchedOutline {
        border-width: 1px !important;
        height: 44px;
        color: black !important;
        border-color: black !important;
      }
    }

    .MuiInputBase-root {
      width: 120px;
    }

    .isActive {
      background-color: $tertiary-color;
      border: 1px solid $tertiary-color !important;
      color: white;
    }
  }
  .myexpense-status {
    padding: 5px 18px;
    border-radius: 5px;
  }
  .status-detail {
    .Asaisir {
      color: $main-blue;
    }
  }
  .Asaisir {
    svg {
      vertical-align: middle;
      padding-right: 5px;
    }
    color: white;
    background: $secondary-blue;
    padding-left: 10px;
  }
  .Avérifier {
    color: #9382ff;
    background: rgba(147, 130, 255, 0.25);
  }
  .Asigner {
    color: $sign-color;
    background: rgba(255, 113, 10, 0.17);
  }
  .Avalider {
    color: #be5083;
    background: rgba(190, 80, 131, 0.17);
  }
  .Arembourser {
    color: #805690;
    background: rgba(128, 86, 144, 0.17);
  }
  .Refusé {
    color: $bright-red;
    background: rgba(241, 78, 72, 0.17);
  }
  .Erreurbanque {
    color: $bright-red;
    background: rgba(241, 78, 72, 0.17);
  }
  .Remboursé {
    color: #2d784c;
    background: rgba(61, 154, 98, 0.17);
  }
  .Nonrenseigné {
    color: #757575;
    background: rgba(74, 86, 96, 0.17);
  }

  .box-myexpense-description {
    font-size: 15px;
  }

  .mybill-content,
  .mybills-total {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiButtonBase-root {
      padding: 5px;
    }
  }

  .mybills-total {
    margin-bottom: 20px;
    span {
      padding-right: 34px;
    }
  }

  .myexpense-detail {
    display: flex;
    padding-left: 20px;

    .detail {
      padding-left: 15px;
    }

    &.status-detail {
      flex-wrap: wrap;

      .detail {
        background: none;
      }
    }

    &.with-description {
      flex-direction: column;
      gap: 2px;
      max-width: 80%;

      .expense-type {
        font-weight: 600;
      }

      .expense-description {
        font-size: 14px;
        color: #666;
        padding-left: 15px;
        line-height: 1.4;
      }
    }
  }

  .detail {
    font-weight: bolder;
  }

  .box-myexpense-content {
    margin-top: 0;
    padding-top: 20px;
    border-top: 1px dashed;
  }

  .box-myexpense-content,
  .mybills,
  .mybills-total {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed;
    margin-left: 25px;
    margin-right: 25px;
  }

  .mybill-amount {
    display: flex;
    column-gap: 20px;
    justify-content: center;
    align-items: center;

    .MuiInputBase-root {
      width: 100px;
    }
  }

  .mybill-description {
    .MuiFormLabel-root,
    .MuiFormControl-root {
      width: 90%;
      margin-left: 30px;
    }
  }

  .align-btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .btn-sign {
    background: $sign-color;

    &:hover {
      color: $sign-color;
      border-color: $sign-color;
    }

    .btn-icon {
      display: flex;
    }

    svg {
      font-size: 15px;
    }
  }

  .myexpenses-display {
    margin: 0 20px 0;

    .expense-summary {
      padding-bottom: 20px;

      .display-expense {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .pdf-btn {
        font-size: 14px;
        text-decoration-line: underline;
      }
      .mybill-buttons {
        display: flex;
        align-items: flex-end;

        .modify-btn {
          color: $secondary-blue;
          font-size: 15px;
          font-weight: bold;
          padding-bottom: 2px;

          @media screen {
            padding: 0;
            padding-right: 10px;
          }
        }
        .delete-btn {
          color: $bright-red;
          padding-right: 0;
        }
      }
    }
  }

  .box-myexpense-footer {
    text-align: center;
    padding: 25px;
  }
}

.popUp-expenses {
  .MuiDialog-container {
    width: 100%;
  }

  .MuiPaper-root {
    width: 100%;
    padding: 10px 15px;
    margin: 5px;
  }

  .box-container-myexpense {
    min-width: 100%;
    max-width: 100%;
  }

  .box-myexpense-content {
    margin-left: 25px;
    margin-right: 25px;
  }

  .myexpenses-display {
    margin: 0;
    padding-top: 10px;
  }

  .divider {
    margin-left: 10px;
    margin-right: 10px;
  }

  .btn-newCollabs {
    width: auto !important;
  }

  &.myexpenses-body {
    .myexpenses-display .expense-summary {
      padding-bottom: 5px;
    }

    .mybill-description .MuiFormLabel-root,
    .mybill-description .MuiFormControl-root {
      margin-left: 0;
    }
  }

  .myexpense-buttons {
    margin: 0;

    .btn-newCollabs {
      margin-bottom: 5px;
    }
  }
}
