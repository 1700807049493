.btn-container {
  margin: 0;

  .btn-newCollabs {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.02em;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
  }

  .background {
    border: 1px solid transparent;
    background-color: $secondary-blue;
    color: white;
    transition: all 0.1s ease-in;

    &:hover {
      color: $secondary-blue;
      background: none;
      border: 1px solid $secondary-blue;
    }
  }

  .red {
    border: 1px solid transparent;
    transition: all 0.1s ease-in;
    background-color: $danger-red;

    &:hover {
      color: $danger-red;
      background: none;
      border: 1px solid $danger-red;
    }
  }

  .cta {
    background-color: $tertiary-color;
    transition: all 0.1s ease-in;

    &:hover {
      background: none;
      border: 1px solid $tertiary-color;
      color: $tertiary-color;
    }
  }

  .no-background {
    background: none;
    color: $dark-grey;
    font-size: 16px;
    padding-left: 0;

    &:hover {
      font-weight: 600;
    }
  }

  .reverse {
    background-color: white;
    color: $secondary-blue;
    border: 1px solid $secondary-blue;
    transition: all 0.1s ease-in;

    &:hover {
      color: white;
      background: $secondary-blue;
      border: 1px solid transparent;
    }
  }

  .isActive {
    background-color: $tertiary-color;
    color: white;
    border: 1px solid $tertiary-color;
    font-weight: bold;

    &:hover {
      background-color: white;
      color: $tertiary-color;
      border: 1px solid $tertiary-color;
    }
  }

  .btn-icon {
    padding-right: 10px;
  }

  button:disabled {
    background-color: $mid-light-grey;
    color: white;
    cursor: not-allowed;
  }
}

@media screen and (max-width: 760px) {
  .btn-container .btn-newCollabs {
    font-size: 13px;
    padding: 8px 16px;
  }

  .btn-container .cta {
    font-size: 15px;
    padding: 10px 40px;
  }
}
