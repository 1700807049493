.header {
  text-align: center;
  padding-bottom: 50px;
  h3 {
    margin: 0;
  }

  .big-title {
    font-size: 40px;
    font-family: $secondary-font;
  }
}

.inputs {
  padding-bottom: 30px;
  display: flex;
  align-items: last baseline;
  gap: 15px;
}

.metrics {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
}

.leaves-outs {
  display: flex;
  justify-content: space-between;
  row-gap: 15px;
  column-gap: 15px;
  margin-bottom: 15px;

  > * {
    flex: 1;
  }
}

@media screen and (max-width: 1150px) {
  .leaves-outs {
    flex-wrap: wrap;
    justify-content: center;

    > * {
      flex: auto;
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 680px) {
  .dashboard {
    .metrics {
      justify-content: center;
      column-gap: 15px;
    }

    .inputs {
      flex-direction: column;
      align-items: center;
    }

    .btn-container,
    .select,
    .btn-newCollabs,
    .MuiFormControl-root,
    .MuiInputBase-root {
      width: 100% !important;
    }
  }
}

.message-info {
  display: flex;
  justify-content: space-between;
  cursor: auto;

  #delete-icon {
    cursor: pointer;
    color: $danger-red;
  }

  #edit-icon {
    cursor: pointer;
    color: $mid-grey;
  }

  .update-icons {
    padding-left: 10px;
  }
}
