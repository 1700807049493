@import '/src/styles/variables.scss';

.documents-body {
  tr:hover td {
    background-color: initial;
    color: inherit !important;
    cursor: auto;
  }
}
.documents-requests {
  .MuiSvgIcon-root {
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
  }

  #download-file-icon {
    color: $tertiary-color;
  }

  #preview-file-icon {
    color: $tertiary-color;
  }
}
