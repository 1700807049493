@import '/src/styles/variables.scss';

.modify-button {
  margin-top: 16px;

  .btn-modify-quota:disabled {
    background-color: $light-grey;
    color: black;

    &:hover {
      border-color: $background-white;
    }
  }
}

.clients-list {
  .MuiBox-root tr:hover td {
    background-color: initial;
    color: inherit !important;
    cursor: auto;
  }
  .MuiBox-root tr td {
    font-size: 14px !important;
  }
}

.client {
  .search-box .MuiPaper-root {
    width: 333px !important;
  }
}

@media screen and (max-width: 1000px) {
  .collabs-list .MuiBox-root .MuiTableCell-root {
    padding: 16px 20px;
  }
}
