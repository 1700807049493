.settings {
  .form {
    justify-content: flex-start;
    margin-top: 15px;
    width: 50%;

    > * {
      width: 100%;
    }

    .MuiFormControl-root {
      width: 100% !important;
      .MuiOutlinedInput-root {
        width: 100% !important;
        margin: auto;
        padding: 2%;
        padding-bottom: 3%;
      }
    }
  }

  .setting-input {
    margin-bottom: 15px;
  }

  .setting-field-copy {
    display: flex;
    align-items: center;
    column-gap: 5px;

    #copy-icon {
      font-size: 18px;
      color: $main-blue;
      cursor: pointer;
    }
  }
}
.sub-form {
  display: flex;
  flex-direction: column;
  padding-left: 15px;

  .porfilImg-label {
    padding-bottom: 15px;
  }
}
.custom-width {
  display: flex;
  margin-top: 50px;
  width: 70% !important;
  justify-content: baseline;
}

@media screen and (max-width: 1300px) {
  .settings .form {
    width: 70%;
  }
}

@media screen and (max-width: 1000px) {
  .settings .form {
    width: 100%;
  }
}

.bold {
  font-weight: bold !important;
}
