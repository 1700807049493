.holidays-requests-status {
  display: flex;
  column-gap: 20px;

  &.absolute-buttons {
    align-items: center;
    position: absolute;
    right: 0;
    top: 142px;
  }

  .btn-newCollabs {
    width: inherit !important;
  }

  .btn-request-accept {
    background: white;
    border-color: $light-green;
    color: green;

    &:hover {
      border-color: $light-green;
      background: green;
      color: white;
    }

    &:disabled {
      border-color: $mid-light-grey;

      &:hover {
        background-color: $mid-light-grey;
        color: white;
      }
    }
  }

  .btn-request-refuse {
    border-color: $light-red;
    color: red;

    &:hover {
      background: red;
      color: white;
    }

    &:disabled {
      border-color: $mid-light-grey;

      &:hover {
        background-color: $mid-light-grey;
        color: white;
      }
    }
  }

  #icon-request-accept {
    color: green;
    cursor: pointer;

    &:hover {
      background: green;
      color: white;
    }
  }

  #icon-request-refuse {
    color: red;
    cursor: pointer;

    &:hover {
      background: red;
      color: white;
    }
  }
}
