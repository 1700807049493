.interview-popup {
  .comment {
    .MuiTextField-root {
      width: 100%;
    }

    .MuiInputBase-multiline {
      height: 200px !important;
      width: 100%;

      // gestion scroll sur multiline textField
      #comment {
        padding-top: 0 !important;
        height: 180px !important;
        overflow: auto !important;
      }
    }
  }

  .rdw-editor-main {
    border: 1px solid $light-grey;
    min-height: 100px;
    padding: 5px;
  }
}
