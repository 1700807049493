@import '/src/styles/variables.scss';

.modify-button {
  margin-top: 16px;

  .btn-modify-quota:disabled {
    background-color: $light-grey;
    color: black;

    &:hover {
      border-color: $background-white;
    }
  }
}

.collabs-list {
  .MuiBox-root .MuiTableCell-root {
    padding: 16px 5px;
  }
}

@media screen and (max-width: 1000px) {
  .collabs-list .MuiBox-root .MuiTableCell-root {
    padding: 16px 20px;
  }
}
