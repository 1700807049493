.holidays-requests {
  position: relative;

  .MuiBox-root tr:hover td {
    background-color: initial;
    color: inherit !important;
    cursor: auto;
  }

  .Accepté {
    color: $checkBox-green;
  }

  .Refusé {
    color: $bright-red;
  }

  .Annulé {
    color: black;
  }

  .MuiTableContainer-root {
    overflow-x: hidden;
  }

  .MuiBox-root .MuiTableCell-root {
    padding: 25px 10px;
  }

  .MuiTableBody-root .MuiTableCell-root {
    padding: 8px 10px;
  }

  .icons-with-trash {
    display: flex;
    flex-direction: row;
    width: 35px;
    column-gap: 10px;

    #canceled-icon {
      cursor: pointer;
      color: $mid-dark-grey;
      font-size: 16px;
    }
  }

  .button-export-synthesis {
    position: absolute;
    right: 0;
    top: 142px;
  }
}

@media screen and (max-width: 400px) {
  .holidays-requests .MuiBox-root .MuiTableCell-root {
    padding: 16px 12px;
  }
}
