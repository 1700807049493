.box-groups-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: white;
  margin: 10px 0;
  padding: 20px;
  border-radius: 10px;

  .buttons-container {
    display: flex;
    column-gap: 15px;

    #delete-icon {
      cursor: pointer;
      color: $danger-red;
    }

    #edit-icon {
      cursor: pointer;
      color: $mid-grey;
    }
  }

  .members {
    display: flex;
    flex-wrap: wrap;

    .group-collab-name {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .collab-name {
        margin: 0 20px 0 10px;
        font-size: 14px;
      }

      .profil-img img {
        width: 50px;
        height: 50px;
      }
    }
  }
}
