@import '/src/styles/variables.scss';

.inactive-user-banner {
  background-color: $weak-opacity-blue;
  border: 1px solid $low-opacity-blue;
  color: $main-blue;
  padding: 12px;
  margin-bottom: 16px;
  margin-top: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}
