.uploader {
  ul {
    padding-left: 0;

    p {
      color: $danger-red;
    }
  }

  .dropzone-img,
  .dropzone-img .img-zone,
  .dropzone-img .img-zone img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .reactEasyCrop_CropArea {
    height: 154px !important;
    width: 154px !important;
  }

  .dropzone-img {
    .img-zone {
      position: relative;
      cursor: pointer;

      img {
        display: block;
        opacity: 0.6;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .dropzone-pdf {
    height: 60px;
    width: 100%;
  }

  .pdf-zone {
    width: 100%;
    cursor: pointer;

    #edit-pdf-icon {
      color: $light-blue;
    }

    #add-pdf-icon {
      color: $tertiary-color;
    }

    .upload-box {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      padding-left: 5px;
      padding-right: 5px;

      .MuiSvgIcon-root {
        color: $main-blue;
      }
    }

    p {
      padding: 0;
      word-break: break-word;
    }
  }
  .dropzone {
    object-fit: cover;
    border: 2px dashed $main-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &:hover {
      border: 2px dashed $tertiary-color;

      .upload-box .MuiSvgIcon-root {
        color: $tertiary-color;
      }
    }

    .overlay {
      background: black;
    }

    &:hover {
      .add-doc .MuiSvgIcon-root {
        color: $secondary-blue;
        scale: 1.1;
      }
    }

    .MuiSvgIcon-root {
      color: rgb(240, 240, 240);
      width: 25px;
    }

    .add-doc {
      .MuiSvgIcon-root {
        color: $main-blue;
        width: 25px;
      }
    }

    .icon {
      position: absolute;
      top: 41%;
      left: 43%;
    }

    .cancel-upload {
      position: absolute;
      top: 0;
      right: 0;
      .MuiSvgIcon-root {
        color: $danger-red;
      }
    }
  }

  #cancel-icon {
    color: $bright-red;
  }

  &.drag {
    border: 2px dashed $secondary-blue;
    background-color: $light-grey;
  }
}

.minimal-design-flex {
  display: flex;
}

.loader-small {
  .MuiCircularProgress-root {
    width: 25px !important;
    height: 25px !important;
  }
}