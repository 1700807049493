.popup-group {
  .MuiTypography-root {
    font-family: 'Montserrat', sans-serif;
  }

  .MuiDialogTitle-root {
    font-size: 1.2rem;
    text-align: center;
  }

  .MuiDialog-paper {
    display: flex;
    padding: 30px 15px;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media only screen and (max-width: 767px) {
      padding: 15px;
    }
  }

  .MuiDialogActions-root {
    width: 100%;
    justify-content: space-around !important;
  }

  .MuiDialogContent-root {
    width: 70%;
    text-align: center;

    @media only screen and (max-width: 767px) {
      width: 90%;
    }
  }

  .btn-container .btn-newCollabs {
    width: 180px;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .text-popup {
    width: 100%;

    .MuiFormControl-root {
      width: 100%;
    }
  }
}
