.stepper {
  display: flex;
  padding: 0;
  list-style: none;
  position: relative;
  margin-left: 19px;
  width: 100%;
  margin-bottom: 2rem;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    content: '';
    width: calc(100% - 20px);
    background: inherit;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 90%;
  }
}

.stepper-item {
  flex: 100%;
  padding: 10px 20px 10px 40px;
  margin: 0 0 0 -10px;
  background: $light-grey;
  -webkit-clip-path: polygon(
    20px 50%,
    0% 0%,
    calc(100% - 20px) 0%,
    100% 50%,
    calc(100% - 20px) 100%,
    0% 100%
  );

  &.is-clickable {
    cursor: pointer;
  }

  &.completed {
    background: $tertiary-color;
    opacity: 1;
    color: white;
  }

  &:first-child {
    padding: 10px 10px 10px 20px;
    margin: 0 0 0 -20px;
    -webkit-clip-path: polygon(
      0% 0%,
      calc(100% - 20px) 0%,
      100% 50%,
      calc(100% - 20px) 100%,
      0% 100%
    );
  }

  &:last-child {
    margin: 0 15px 0 -10px;
    -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}
