.data-visualization-footer {
  display: flex;
  margin-top: 25px;
  justify-content: flex-end;
  .box-buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 15px;
  }
}
@media screen and (max-width: 820px) {
  .box-buttons {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 600px) {
  .data-visualization-footer {
    flex-direction: column;
    row-gap: 25px;
    width: 100%;

    button {
      width: 100%;
    }
    .box-buttons {
      flex-direction: column;
    }
  }
}
