@import '/src/styles/variables.scss';

.planning-container {
  width: 100%;

  .scrollableDiv {
    height: 60vh;
    overflow-y: scroll;
  }

  .row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light-grey;

    .days-displayer {
      display: flex;
      justify-content: space-between;
    }

    .day-count {
      border: 1px solid $weak-opacity-blue;
      box-shadow: 5px 5px 5px -2px $light-grey;
      border-radius: 4px;
      background: $weak-opacity-blue;
      padding: 6px;
      display: inline-block;
      margin-top: 10px;
      color: $secondary-blue;
      font-size: 12px;
    }

    .flex-column {
      display: flex;
      flex-direction: column;
      row-gap: 15px;

      &.day {
        height: auto;
      }
    }
  }

  .MuiCircularProgress-root {
    margin: 0 auto;
    display: flex !important;
  }

  .month-slider {
    position: relative;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-name: slideInFromRight;
  }

  /* On utilise cette classe pour inverser la direction de l'animation */
  .month-slider.reverse {
    animation-name: slideInFromLeft;
  }

  .planning-group-title {
    padding: 5px 5px;
    font-size: 20px;
    font-weight: bold;
  }
}

@media (max-width: 1200px) {
  .planning-container {
    .row {
      .day-count {
        font-size: 10px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .planning-container {
    .scrollableDiv {
      overflow-y: scroll;
    }

    .row {
      .days-displayer {
        justify-content: space-between;

        .day-box {
          width: 50px;
          min-height: 35px;
          font-size: 16px;
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .planning-container .row .days-displayer .day-box {
    font-size: 14px;
    margin: 10px 0;
    width: 45px;
  }
}

@media (max-width: 580px) {
  .planning-container .row .days-displayer .day-box {
    font-size: 12px;
    width: 30px;
  }
}

@media (max-width: 360px) {
  .planning-container .row .days-displayer .day-box {
    width: 20px;
    min-height: 25px;
  }
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

::-webkit-scrollbar-thumb:hover {
  background: #6e6e6e;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(0%);
  }

  45% {
    transform: translateX(-100%);
    opacity: 0;
  }

  55% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(0%);
  }

  45% {
    transform: translateX(100%);
    opacity: 0;
  }

  55% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
