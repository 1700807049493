.box-layout-elts {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  flex-wrap: wrap;
  row-gap: 15px;
  padding-bottom: 40px;

  .trombi-collab {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__left {
      display: flex;
      align-items: center;

      &__user-infos {
        min-width: 170px;

        .trombi-collab-content {
          text-align: center;
          margin: 5px;
        }

        .trombi-collab-subtext {
          font-size: 11px;
          text-align: center;
          margin: 0;
        }

        .email-copy {
          display: flex;
          justify-content: center;
          column-gap: 2px;
        }

        #copy-icon {
          color: $mid-dark-grey;
          cursor: pointer;
          font-size: 14px;
        }
      }

      img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
      }

      .MuiSvgIcon-root {
        color: $main-blue;
        width: 17px;
      }

      @media screen and (max-width: 915px) {
        img {
          width: 50px;
          height: 50px;
        }
        &__user-infos {
          .email-copy {
            overflow: hidden;
            max-width: 200px;
            text-align: center;
          }

          .trombi-collab-content {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .trombi-collab-subtext {
            overflow: hidden;
          }
        }
      }
    }
  }
  @media screen and (max-width: 915px) {
    column-gap: 0px;
  }
}

@media screen and (max-width: 550px) {
  .minPage-container {
    margin-right: 5px;
    margin-left: 5px;
  }
  .trombi-collab {
    display: inline-flexbox;
  }
}

@media screen and (max-width: 440px) {
  .trombi-collab {
    img {
      display: true;
      width: 40px;
      height: 40px;
    }
  }
}
