@import '/src/styles/variables.scss';
@import '../../styles/fonts';

.mes-documents-title {
  .capitalize {
    text-transform: capitalize;
  }

  .warning-container {
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
    margin: 0 0 1.5rem;

    .accentuated-btn {
      font-size: 20px;
      cursor: pointer;
      font-weight: 400;
      font-family: $primary-font;
      background: linear-gradient(90deg, $main-blue 20%, $tertiary-color 70%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    .MuiSvgIcon-root {
      font-size: 50px;
      color: $danger-red;
    }
  }
}
