.month-displayer {
  position: relative;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .button-export {
    position: absolute;
    right: 0;
    bottom: 2px;
  }

  .month-year {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;

    .month-title {
      font-size: 22px;
      padding-right: 2%;
      text-transform: capitalize;
    }
  }

  .icon {
    color: $mid-grey;
    height: 25px;
    cursor: pointer;

    &:hover {
      color: $mid-dark-grey;
    }
  }
}

@media (max-width: 595px) {
  .month-displayer {
    margin-top: 15px;
    justify-content: space-around;

    .month-year .month-title,
    .light {
      font-size: 18px;
    }

    .button-export {
      display: none;
    }
  }
}
