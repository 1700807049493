.info-box {
  margin-top: 50px;

  .info-text {
    font-size: 14px;
    font-weight: 600;
    color: $mid-dark-grey;
    line-height: 1.5;
  }

  .important {
    color: $secondary-blue;
  }
}

.checkBox-box {
  margin: 65px auto;
  width: 80%;
  display: flex;
  gap: 140px;
  flex-wrap: wrap;
  row-gap: 70px;
}

@media screen and (max-width: 1178px) {
  .checkBox-box {
    width: 90%;
    gap: 100px;
    row-gap: 60px;
  }
}

@media screen and (max-width: 1036px) {
  .checkBox-box {
    justify-content: center;
    gap: 80px;
    row-gap: 50px;
  }
}
