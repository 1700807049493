:root {
  --main-color: #1653f0;
  --secondary-color: #0700e0;
  --tertiary-color: #7b01d4;
}

$main-blue: var(--main-color);
$secondary-blue: var(--secondary-color);
$tertiary-color: var(--tertiary-color);

$background-white: #f9f9f9;

$disable-grey: rgba(74, 86, 96, 0.25);
$light-grey: #e6e6e6;
$mid-grey: #b3b3b3;
$mid-light-grey: #757575;
$mid-dark-grey: #828282;
$dark-grey: #333333;

$light-blue: #0700e046;
$low-opacity-blue: #93c5fd;
$weak-opacity-blue: #f5f5fe;

$purple: #e000ca;

$danger-red: #b30000;
$bright-red: #f14e48;
$error: #c73e1d;
$light-red: #fc9d9a;

$orange: #fc6524;
$light-orange: #ee895e;
$sign-color: #ea7e1b;

$yellow: #ffd777;

$green: #6baa55;
$checkBox-green: #30b64e;
$light-green: #a4dba3;
$card-green: #ebfaed;
