.search-box {
  .MuiPaper-root {
    padding: 2px 4px !important;
    display: flex !important;
    align-items: center !important;
    width: 290px !important;
    height: 45px !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    border: 1px solid $light-grey !important;

    &:focus-within {
      border: 1px solid $main-blue !important;

      .MuiSvgIcon-root {
        color: $main-blue;
      }
    }
  }
}
