.place-filter {
  padding-left: 32px;
  padding-bottom: 45px;
  .select {
    .MuiInputLabel-root,
    .MuiInputBase-input {
      color: white !important;
    }
  }

  @media screen and (max-width: 850px) {
    padding-bottom: 20px;
    .MuiInputLabel-root {
      margin-bottom: 7px;
      position: relative;
      left: 35%;
    }
  }
}
