//Propriété pour gérer disposition des éléments de formulaire
.form {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 50px;

  .sub-form {
    display: flex;
    flex-direction: column;
    padding-left: 15px;

    .porfilImg-label {
      padding-bottom: 10px;
    }
  }

  .inline-sub-form {
    display: flex;
    column-gap: 30px;
    flex-wrap: wrap;

    .MuiOutlinedInput-root {
      width: 250px !important;
    }
  }

  .left-content {
    width: 50%;

    .margin-top {
      margin-top: 50px;
    }
  }

  .right-content {
    display: flex;
    flex-direction: column;
    width: 50%;

    .margin-top {
      margin-top: 23px;
    }
  }
}

@media screen and (max-width: 890px) {
  .form {
    flex-direction: column;
    align-items: center;

    .sub-form {
      padding-left: 0px;
    }

    .dash {
      width: 100%;
    }

    .left-content {
      width: 100%;
    }
    .right-content {
      width: 100%;
    }

    .MuiOutlinedInput-root {
      width: 90% !important;
    }

    // datepPicker mui
    .MuiInputBase-adornedEnd,
    .date-input .MuiInputBase-adornedEnd {
      width: 80% !important;
    }

    .third-sub-form {
      margin-top: 50px;
    }

    .right-content {
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 400px) {
  .form {
    .MuiOutlinedInput-root {
      width: 100% !important;
    }
    .MuiInputBase-adornedEnd,
    .date-input .MuiInputBase-adornedEnd {
      width: 100% !important;
    }
  }
}

// Propriétés générales des inputs de formulaire de MUI

/* Input + Select mui */
.MuiOutlinedInput-root {
  border-color: $light-grey !important;
  border-radius: 10px !important;
  height: 44px !important;
}

.MuiFormLabel-asterisk {
  color: $orange !important;
}

.MuiInputBase-multiline {
  height: 90px !important;

  // gestion scroll sur multiline textField
  .MuiInputBase-inputMultiline {
    padding-top: 0px !important;
    height: 75px !important;
    overflow: auto !important;
  }
}

.MuiInputBase-root,
.MuiFormLabel-root,
.MuiMenuItem-root,
.MuiFormControlLabel-label {
  font-family: $primary-font !important;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
  color: black !important;
}

.MuiInputBase-input {
  padding: 18px 14px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: $light-grey !important;
}
// Centrage verticale du texte dans les différents select
.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding-top: 3px !important;
}
.MuiOutlinedInput-input {
  padding-top: 20px !important;
}

// Labels
.MuiInputLabel-root {
  color: black !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.MuiSelect-root,
.MuiOutlinedInput-input {
  color: $dark-grey !important;
}

.popUp {
  .MuiTypography-root {
    font-family: 'Montserrat', sans-serif;
  }

  .MuiDialogTitle-root {
    font-size: 1.2rem;
  }

  .MuiDialog-paper {
    display: flex;
    padding: 30px 15px;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .MuiDialogActions-root {
    width: 100%;
    justify-content: space-around !important;
  }

  .MuiDialogContent-root {
    width: 70%;
    text-align: center;
  }

  .btn-container .btn-newCollabs {
    width: 180px;
  }

  .text-popup {
    width: 100%;
    .MuiFormControl-root {
      width: 100%;
    }
  }
}

// bouton close d'un popOver
.MuiPopover-paper #closeIcon,
.MuiPaper-root #closeIcon {
  position: absolute;
  top: 0;
  right: 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;

  .MuiCircularProgress-colorPrimary {
    color: $secondary-blue !important;
  }
}

.GroupRadio {
  .MuiFormGroup-root {
    flex-direction: row !important;
    column-gap: 30px;

    .MuiFormControlLabel-root {
      margin: 0;
    }

    .MuiRadio-root {
      padding: 5px;
    }

    .MuiSvgIcon-root {
      height: 20px;
      width: 20px;
    }

    .MuiRadio-colorPrimary,
    .MuiButtonBase-root .Mui-checked {
      color: $main-blue;
    }

    .Mui-disabled {
      .MuiRadio-colorPrimary,
      .MuiButtonBase-root .Mui-checked {
        opacity: 0.7;
      }
    }
  }
}

// pour le toggle
.MuiFormControlLabel-root {
  display: flex;
  flex-direction: column-reverse;
}

.MuiSwitch-root {
  .Mui-checked {
    color: $secondary-blue !important;
  }
  .Mui-disabled {
    opacity: 0.7;
  }
}
.MuiSwitch-track {
  background-color: $secondary-blue !important;
}

.css-10hburv-MuiTypography-root {
  font-family: 'Montserrat', sans-serif !important;
}

.editable-text {
  .MuiOutlinedInput-root {
    height: 30px !important;
  }
}
