.profil-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 15px;
  justify-content: space-between;
  margin-bottom: 20px;

  &__left {
    display: flex;
    align-items: center;
    column-gap: 25px;

    &__material__infos {
      display: flex;
      column-gap: 30px;
      align-items: center;
    }

    &__user-infos {
      display: flex;
      flex-direction: column;
    }

    h3 {
      font-size: 32px;
      padding: 0;
      margin: 0;
    }

    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }
  }
}
.bank-details {
  display: flex;
  column-gap: 40px;
  flex-wrap: wrap;
}

.copy-email {
  color: $main-blue;
  width: 17px !important;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .copy-email {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .profil {
    .uploader .dropzone .img-zone,
    .uploader .dropzone img,
    .uploader .dropzone,
    .uploader img {
      width: 100px;
      height: 100px;
    }
  }
}
