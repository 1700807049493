.box-container-myholidays {
  .GroupRadio {
    display: flex;
    .MuiFormGroup-root {
      padding-top: 15px;
      justify-content: space-between;

      .MuiFormControlLabel-label {
        font-size: 12px;
      }

      .MuiFormControlLabel-root {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .ask-form {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;

    .btn-newCollabs {
      width: inherit !important;
    }

    .align-datePickers {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px 10px;

      > * {
        width: 100%;
      }
    }

    .select .MuiInputBase-root {
      width: 100%;
    }

    .align-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
}

@media screen and (max-width: 450px) {
  .ask-form {
    .align-datePickers {
      flex-direction: column;
    }
  }
}
