.color-guide {
  display: flex;
  column-gap: 25px;
  row-gap: 15px;
  flex-wrap: wrap;
  align-items: baseline;

  .active {
    background: $secondary-blue;
    color: white;
  }

  .not-btn {
    cursor: default !important;

    &:hover {
      border: 1px solid $weak-opacity-blue !important;
    }
  }

  button {
    background: $weak-opacity-blue;
    padding: 12px 12px;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      border: 1px solid $secondary-blue;
    }
  }

  .color {
    display: flex;
    column-gap: 10px;
    align-items: center;

    .legend {
      border: 1px solid rgba(0, 0, 0, 0.137);
      width: 16px;
      height: 17px;
    }

    .legend-count {
      font-weight: bold;
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 50%;
    }
  }
}

@media (max-width: 1100px) {
  .legend-box-mobile {
    padding: 15px;
  }
  #legendPopover {
    width: 200px;
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
  }
  .color-guide {
    display: flex;
    column-gap: 10px;
    row-gap: 15px;
    flex-wrap: wrap;
    align-items: baseline;
  }
}
