.client-img {
  img {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    object-fit: cover;
    padding: 0;
  }

  .zoom-in-cursor {
    cursor: zoom-in;
  }

  .zoom-in {
    scale: 2.2;
    cursor: zoom-out;
  }
}
