.data {
  .mobile-filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiSvgIcon-root {
      color: $mid-dark-grey;
      font-size: 30px;
      cursor: pointer;

      &:hover {
        color: $dark-grey;
      }
    }
  }

  &__header {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 35px;

    &__search {
      display: flex;
      justify-content: flex-start;
      column-gap: 25px;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 15px;
    }

    &__filters {
      display: flex;
      justify-content: flex-start;
      column-gap: 45px;
      align-items: center;
      flex-wrap: wrap;

      > * {
        margin-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: 650px) {
    &__header {
      &__search {
        .search-box {
          width: 98%;
          .MuiPaper-root {
            width: 100% !important;

            .MuiInputBase-input {
              font-size: 14px;
            }
          }
        }
        .btn-container {
          width: 100%;

          .btn-newCollabs {
            width: 100%;
            font-size: 14px;
          }
        }
        margin-bottom: 35px;
      }

      &__filters {
        width: 100%;

        > * {
          width: 100%;
          .MuiOutlinedInput-root {
            width: 100% !important;
          }
        }
      }
    }
  }
}
