$p: (
  1: 1%,
  2: 2%,
  3: 3%,
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  60: 60%,
  70: 70%,
  80: 80%,
  90: 90%,
  100: 100%
);
@each $name, $value in $p {
  .p-#{$name} {
    padding: $value !important;
  }
}

.pb-1 {
  padding-bottom: 1%;
}

.pt-5 {
  padding-top: 5%;
}

.pt-1 {
  padding-top: 1%;
}
