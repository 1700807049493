.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;

  h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
    color: $secondary-blue;
  }

  p {
    font-size: 1.5rem;
  }

  img {
    width: 50%;
    max-width: 600px;
  }
}

@media screen and (max-width: 1250px) {
  .not-found {
    h1 {
      font-size: 3rem;
    }

    p {
      font-size: 1.2rem;
    }
  }
}

@media screen and (max-width: 1250px) {
  .not-found {
    h1 {
      font-size: 3rem;
    }

    p {
      font-size: 1.2rem;
    }

    img {
      width: 60%;
    }
  }
}

@media screen and (max-width: 930px) {
  .not-found {
    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1rem;
    }

    img {
      width: 80%;
    }
  }
}
