$w: (
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  50: 50%,
  60: 60%,
  70: 70%,
  75: 75%,
  80: 80%,
  90: 90%,
  100: 100%
);
@each $name, $value in $w {
  .w-#{$name} {
    width: $value !important;
  }
}
