.box-container-myholidays {
  .my-request-body {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
  }

  .scrollableDiv {
    height: 50vh;
    overflow-y: scroll;
    padding: 0 15px;
    margin-top: 15px;
  }

  .MuiSvgIcon-root {
    font-size: 16px;
  }

  #accepted-icon {
    color: $green;
  }

  #canceled-icon {
    color: $mid-dark-grey;
  }

  #refused-icon {
    color: $danger-red;
  }

  #toValidate-icon {
    color: $orange;
  }

  .color-guide {
    padding-top: 15px;
  }
}
