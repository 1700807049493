.justificatif-confirmation {
  text-align: center;
  padding: 5px;

  .modal-text {
    padding-bottom: 10px;
  }

  .holidays-requests-document {
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: #757575;
  }
}
