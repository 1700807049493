.button-synthesis {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 142px;
}

.button-synthesis-export {
  margin-left: 10px;
  margin-top: 20px;
}

@media (max-width: 595px) {
  .button-synthesis {
    display: none;
  }
}
