.form {
  .MuiOutlinedInput-root {
    width: 60% !important;
  }

  .date-input .MuiInputBase-adornedEnd {
    width: 40% !important;
  }

  .expenses {
    p {
      font-size: 14px;
    }

    .expenses-toggle-box {
      display: flex;
      align-items: flex-start;
      column-gap: 60px;
    }

    .switch-label {
      display: flex;
      align-items: center;
      column-gap: 15px;
      height: 50px;
    }

    .MuiSwitch-root {
      margin-left: -12px;
    }

    .MuiFormControl-root {
      margin-left: 15px;
    }
  }
}

@media screen and (max-width: 1148px) {
  .form {
    .MuiOutlinedInput-root {
      width: 80% !important;
    }

    // datepPicker mui
    .MuiInputBase-adornedEnd {
      width: 70% !important;
    }
  }
}
