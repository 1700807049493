$margins: (
  1: 1%,
  2: 2%,
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  60: 60%,
  70: 70%,
  80: 80%,
  90: 90%,
  100: 100%
);
@each $name, $value in $margins {
  .ml-#{$name} {
    margin-left: $value !important;
  }
}

@each $name, $value in $margins {
  .m-#{$name} {
    margin: $value !important;
  }
}

@each $name, $value in $margins {
  .mt-#{$name} {
    margin-top: $value !important;
  }
}

@each $name, $value in $margins {
  .mb-#{$name} {
    margin-bottom: $value !important;
  }
}

.margin-auto {
  margin: auto;
}

.m0 {
  margin: 0;
}

.mt-1 {
  margin-top: 1%;
}

.mb-1 {
  margin-bottom: 1%;
}

.mb-2 {
  margin-bottom: 2%;
}
