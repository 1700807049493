.metric-card {
  background-color: white;
  padding: 25px 33px;
  border-radius: 10px;
  min-width: 400px;
  border: 1px solid rgba(255, 255, 255, 0);
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    border-color: $light-grey;
  }

  .metric-card__title {
    display: flex;
    padding-bottom: 10px;

    h5 {
      padding-left: 7px;
    }
  }

  .arrivals {
    color: $green;
  }
  .leaving {
    color: $orange;
  }
  .last-activities {
    color: $secondary-blue;
  }
  .holidays-requests {
    color: $secondary-blue;
  }

  .card-users-info {
    height: 300px;
    overflow-y: auto;
    padding-left: 10px;

    .card-user-info {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 20px;
      cursor: pointer;

      .card-left-content {
        display: flex;

        .sub-infos {
          display: flex;
        }

        img {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          margin-right: 15px;
        }

        .user-name {
          display: flex;
          font-size: 14px;
          font-weight: 400;
          color: $dark-grey;
          margin: 0;

          #missing-info-icon {
            color: $light-orange;
            margin-left: 5px;
            font-size: 18px;
          }
        }

        .first-info,
        .second-info {
          font-size: 10px;
          font-weight: 400;
          color: $mid-light-grey;
          margin: 0;
          font-style: italic;
        }

        .second-info {
          padding-left: 20px;
        }

        .second-info:before {
          background-color: $secondary-blue;
          content: '';
          display: inline-block;
          height: 2px;
          position: relative;
          vertical-align: middle;
          width: 10px;
          margin-right: 10px;
        }
      }

      .card-middle-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;

        .holiday-type {
          font-size: 14px;
          font-weight: 400;
          color: $dark-grey;
        }

        .document-link {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60%;
          p {
            font-size: 10px;
            font-weight: 400;
            color: $mid-light-grey;
            margin: 0;
            font-style: italic;
          }
        }
      }

      .card-right-content {
        display: flex;
        column-gap: 20px;

        .date {
          font-size: 14px;
          font-weight: 500;
          margin: 0;
        }
        .important-info {
          color: $secondary-blue;
        }

        .action-info {
          font-weight: bold;
          color: $green;
        }
        .delete-action {
          color: $danger-red;
        }
      }

      &:hover {
        background-color: $light-grey;
      }
    }
  }
}

.arrival-metric {
  .loader {
    height: inherit;
  }
}

.holidays-requests-body {
  padding-top: 10px;
}

@media screen and (max-width: 1400px) {
  .card {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 850px) {
  .metric-card {
    padding: 15px 13px;
    .second-info {
      display: none;
    }
  }
}

@media screen and (max-width: 470px) {
  .metric-card {
    min-width: 100px;

    .card-users-info {
      padding-left: 0;
    }
    .display-none-on-mobile {
      display: none;
    }
  }
}
