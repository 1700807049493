.publication-banner {
  position: absolute;
  background: white;
  top: 0;
  right: 0;
  box-shadow: 5px 5px 5px -2px $light-grey;
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, $main-blue, $tertiary-color);
  border-left: 0;
  border-right: 0;
  border-top: 0;
  width: calc(100% - 255px);
  padding-left: 20px;
  overflow: hidden;
  min-height: 35px;
  line-height: 35px;

  &.close-menu {
    width: calc(100% - 55px);
  }

  @media screen and (max-width: 550px) {
    width: calc(100% - 60px) !important;

    .text-animation {
      display: inline-block;
      white-space: nowrap;
      animation: defilement 30s linear infinite;

      p {
        font-size: 12px;
      }
    }
  }
}

@keyframes defilement {
  0% {
    transform: translateX(40%);
  }
  100% {
    transform: translateX(-100%);
  }
}
