.box-data {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #d9d9d9;
  padding-top: 20px;
  padding-bottom: 20px;

  .os-select {
    .MuiInputBase-root {
      width: 300px;
    }
  }

  .MuiInputBase-root {
    width: 300px;
  }

  .category-title {
    font-size: 16px;
    font-weight: 700;
    color: $dark-grey;
    margin: 0;
    width: 255px;
    flex-shrink: 0;
    padding-right: 100px;
  }

  .data-title {
    color: $mid-dark-grey;
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 3px;
  }

  .data-value {
    color: black;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    word-wrap: break-word;
  }

  .attribution-box {
    display: flex;
    align-items: center;
    column-gap: 10px;

    .MuiSvgIcon-root {
      color: $secondary-blue;
      cursor: pointer;

      &:hover {
        color: $main-blue;
      }
    }
  }

  .box-inline-elts {
    display: flex;
    align-items: flex-start;
    column-gap: 40px;
    flex-wrap: wrap;

    .row {
      padding-bottom: 15px;
    }
  }

  .small-gap {
    column-gap: 20px;
  }

  .box-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 15px;
    flex-wrap: wrap;

    .link {
      text-decoration: underline;
      color: $main-blue;
      cursor: pointer;
    }

    .no-custom-select {
      width: 300px;
    }

    .pb-15 {
      padding-bottom: 15px;
    }
  }

  .MuiFormControl-root {
    width: 300px;
  }

  .number-field {
    .MuiFormControl-root {
      width: 150px;
    }
  }
}

@media screen and (max-width: 1115px) {
  .box-data {
    .data-value {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 700px) {
  .box-data {
    display: block;

    .category-title {
      padding-bottom: 15px;
      width: 100%;
      padding-right: 0;
    }
    .box-inline-elts {
      column-gap: 25px;
    }
  }
}

@media screen and (max-width: 600px) {
  .box-data,
  .box-column,
  .box-inline-elts {
    width: 100%;
    > * {
      width: 100%;
    }
    .MuiInputBase-root {
      width: 100%;
    }

    .small-gap {
      > *:not(:last-child) {
        padding-bottom: 15px;
      }
    }
  }
  .box-data {
    .MuiTextField-root {
      width: 100% !important;
    }
    .box-column {
      .MuiInputBase-root,
      .MuiFormControl-root {
        width: 100% !important;
      }
    }
  }
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-start {
  display: flex;
}
