.select-box {
  display: flex;
  column-gap: 15px;

  #services {
    max-width: 120px;
  }

  .MuiSelect-select {
    display: flex;
    padding-left: 10px !important;
    height: 25px !important;
  }
}

@media screen and (max-width: 650px) {
  .select-box {
    flex-direction: column;
    row-gap: 15px;
    width: 100%;
  }
}
