.month-calendar {
  &.space {
    margin-right: 50px;
  }

  .month-group {
    position: relative;
    margin-top: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .month-calendar-days {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .month-displayer-title {
    font-size: 16px;
    padding-right: 2%;
    text-transform: capitalize;
    padding-bottom: 17px;
    padding-top: 17px;
  }

  .week-displayer {
    display: flex;
    align-items: center;
    justify-content: space-around;

    &.week-border {
      border-bottom: 1px solid $light-grey;
      border-left: 1px solid $light-grey;

      &:first-child {
        border-top: 1px solid $light-grey;
      }
    }
  }

  .day-displayer {
    position: relative;
    text-align: center;
    font-size: 14px;
    width: 32px;
    height: 32px;

    &.day-border {
      border-right: 1px solid $light-grey;
    }

    .validation-icon .MuiSvgIcon-root {
      width: 16px;
      position: absolute;
      top: -10px;
      right: -3px;
      z-index: 10;
      color: $light-orange;
    }
  }
}

@media (max-width: 1295px) {
  .month-calendar {
    &.space {
      margin-right: 20px;
    }
  }
}

@media (max-width: 1195px) {
  .month-calendar {
    &.space {
      margin-right: 10px;
    }
  }
}

@media (max-width: 595px) {
  .month-calendar {
    &.space {
      margin-right: 0;
    }
  }
}
