@import '/src/styles/variables.scss';

.onboarding {
  margin-top: 50px;
}

.step {
  margin: 10px;
  width: 39px;
  height: 39px;
  border-radius: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: $main-blue;
  border: 1px solid $main-blue;
}

.step-active {
  color: white;
  background-color: $main-blue;
}

.step-line {
  width: 105px;
  @media screen and (max-width: 450px) {
    width: 50px;
  }
  border-bottom: 1px solid #0000004d;
}

.flex-column {
  flex-direction: column;
}

.text-container {
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
  width: 165px;
  @media screen and (max-width: 450px) {
    width: 105px;
  }
  text-align: center;
}

.text {
  width: 139px;
}

.step-1 {
  display: flex;
  margin-top: 100px;
  justify-content: center;
  @media screen and (max-width: 1150px) {
    margin-top: 50px;
    flex-direction: column;
  }
}

.card {
  position: relative;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
  border-radius: 30px;
  margin-left: 15px;
  margin-right: 15px;
  width: 189px;
  height: 237px;
  padding: 10px;
  margin-top: 20px;
  @media screen and (max-width: 1150px) {
    margin: 30px;
    width: calc(100% - 75px);
    height: 110px;
  }
}

.card-full {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%231653F0FF' stroke-width='4' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.card-empty {
  border: 1px solid $main-blue;
}

.card-image {
  width: 40%;
  @media screen and (min-width: 1150px) {
    width: 100%;
  }
  object-fit: contain;
}

.h-half {
  height: 50%;
  @media screen and (max-width: 1150px) {
    height: 100%;
  }
}

.card-container {
  height: 100%;
  align-items: center;
  @media screen and (max-width: 1150px) {
    display: flex;
    flex-direction: row;
  }
}

.uploader {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-1-text {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
}

.btn-step-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.d-none {
  display: none;
}

.pointer {
  cursor: pointer;
}

.checked {
  position: absolute;
  top: -20px;
  right: -20px;
}
