@import '/src/styles/variables.scss';

.day-box {
  position: relative;
  width: 3%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  text-align: center;

  .validation-icon .MuiSvgIcon-root {
    width: 16px;
    position: absolute;
    top: -10px;
    right: -5px;
    color: $light-orange;
  }

  .day {
    background: none;
    border: none;
    font: inherit;
    outline: inherit;
    padding: 0;
    width: 100%;
    height: 100%;
  }
}

.no-respected-rule-day {
  border: 1px solid $danger-red;
  .day {
    color: $danger-red;
  }
}

.TT-respected-special-rule-day {
  border: 1px solid $checkBox-green;
  .day {
    color: $checkBox-green;
  }
}

.day-respected .day {
  color: black;
}

.light-greyText,
.light-greyText .day {
  font-weight: 700;
  color: $disable-grey !important;
}

.today-date,
.today-date .day {
  color: $main-blue;
}

.days-hover:hover {
  background-color: $light-grey;

  .day {
    cursor: pointer;
  }
}

.profil-section {
  display: flex;
  min-width: 220px;
  align-items: center;

  .MuiSvgIcon-root {
    cursor: pointer;
    margin-right: 5px;
    font-size: 16px;
    color: rgb(255, 187, 0);
  }

  .no-star-cursor {
    cursor: auto;
  }

  .profil-img {
    display: flex;
    align-items: center;
  }

  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    max-width: 100%;
    max-height: 100%;
    margin: 5px 0;
  }

  .text-profile {
    padding-left: 15px;
  }
}

@media (max-width: 1400px) {
  .day-box .day {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .day-box .day {
    font-size: 12px;
  }
}

@media (max-width: 700px) {
  .profil-image {
    display: none;
  }

  .profil-section {
    width: 120px;

    .profil-img {
      display: none;
    }

    .text-profile {
      padding-left: 0;
    }
  }
}

@media (max-width: 500px) {
  .profil-section {
    .text-profile p {
      font-size: 12px;
    }
  }
}
