.MuiBox-root {
  position: relative;

  .mobile-filter {
    display: none;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .MuiPaper-rounded {
    border-radius: 20px !important;
  }

  .MuiTableRow-head {
    &:hover {
      .MuiTableCell-root {
        background-color: none;
      }
    }

    .MuiTableCell-head {
      font-weight: 600;
      font-size: 14px;
    }
  }

  // custom des tr de la table
  tr {
    &:hover {
      td {
        cursor: pointer;
        background-color: $secondary-blue;
        color: white !important;
      }
    }
  }

  // custom des icons de filtrage quand actif
  .Mui-active .MuiTableSortLabel-icon {
    color: $secondary-blue !important;
  }

  // custom des icons de filtrage quand hover
  .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon {
    opacity: 1;
    color: $secondary-blue !important;
  }

  // Mise en forme des labels icon filtre
  .MuiTableSortLabel-root:hover {
    color: currentColor;
  }

  // Mise en forme des icon de filtre
  .MuiTableSortLabel-icon {
    opacity: 1;
    font-size: 30px;
    color: $light-blue !important;
  }

  .MuiTableBody-root {
    .MuiTableCell-body {
      font-size: 12px;
      border: none;
    }

    .MuiTableRow-root {
      border-top: 1px solid #cdccf9;
    }
  }

  // réduit le padding des cellules
  .MuiTableCell-root {
    font-family: 'Montserrat', sans-serif;
    padding: 16px 20px;
    color: $dark-grey !important;

    .MuiSvgIcon-root {
      color: $checkBox-green;
    }
  }

  .MuiTableCell-head {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .check-icon-headcell {
    padding: 16px 20px;
  }

  img {
    width: 42px;
    height: 42px;
    padding: 0 15px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .MuiPagination-root .Mui-selected {
      color: $secondary-blue;
      border: 2px solid $secondary-blue;
      background-color: white;
    }

    .MuiPaginationItem-root {
      width: 32px;
      border-color: #dfe3e8;
      color: black;
      font-weight: bold;

      &:hover {
        color: $secondary-blue;
        border: 2px solid $secondary-blue;
        background-color: white;
      }
    }

    .Mui-disabled {
      opacity: 0.5;
      background-color: #919eab;

      .MuiPaginationItem-icon {
        color: #c4cdd5;
      }
    }
  }
}

@media screen and (max-width: 1374px) {
  .MuiBox-root {
    .MuiTableRow-head {
      .MuiTableCell-head {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .table {
    .md-delete {
      display: none;
    }
  }
}

@media screen and (max-width: 1000px) {
  .table {
    .sx-delete {
      display: none;
    }
  }
}

@media screen and (max-width: 650px) {
  .MuiBox-root {
    .sx-mini-delete {
      display: none;
    }

    .mobile-filter {
      display: block;
    }
  }
}
