.timeline-container {
  display: flex;
  margin-bottom: 2rem;

  .button-interview {
    z-index: 10;
    position: absolute;
  }

  .timeline {
    width: 60%;
  }

  .interview-reviews {
    background: white;
    border-radius: 5px;
    flex: 1;
    padding: 25px;
    font-size: 14px;

    .review {
      display: flex;

      .review-content {
        padding-left: 10px;
        flex: 1;

        .review-comment {
          display: flex;
          justify-content: space-between;
          font-style: italic;

          .MuiTextField-root {
            width: 100%;
          }

          svg {
            cursor: pointer;
          }
        }

        .review-date {
          padding-top: 20px;
          color: $mid-grey;
        }
      }
    }

    hr {
      height: 1px;
      background-color: $mid-grey;
      border: none;
      margin: 20px 0;
    }
  }

  .btn-container {
    margin-bottom: 2rem;
  }

  .MuiTimeline-root {
    .timeline-content {
      font-size: 14px !important;

      .interview-details {
        display: inline-block;
        background: white;
        padding: 20px;
        border-radius: 5px;
        position: relative;
        text-align: left;

        .date {
          font-weight: 700;
        }

        .comment {
          position: absolute;
          top: 5px;
          right: 5px;
          cursor: pointer;
          color: $mid-light-grey;
        }

        .link-container {
          text-align: right;
          padding-top: 20px;

          .link {
            cursor: pointer;
            color: $secondary-blue;
            text-decoration: none;
          }
        }

        .income-badge {
          display: table;
          background: $tertiary-color;
          color: white;
          border-radius: 3px;
          padding: 3px;
        }

        .actions {
          text-align: right;
          margin-top: 8px;

          svg {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.chart-container {
  background: white;
  padding: 35px;
  border-radius: 5px;
}

@media (max-width: 800px) {
  .timeline {
    margin-top: 15px;
  }

  .MuiTimeline-root {
    .timeline-content {
      .comment {
        font-size: 15px;
      }
    }
  }
}
