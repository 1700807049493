@import '/src/styles/variables.scss';

.total-requests {
  background-color: $main-blue !important;

  &:hover {
    color: #ffffff !important;
  }
}

.total-cell {
  &.MuiTableCell-root {
    font-weight: bold;
    color: #ffffff !important;
  }
}

.profil-image-column {
  width: 70px;
}

.name-column {
  width: 150px;
}
