.popup {
  .accentuated-title {
    text-align: center;
  }
  .MuiDialogTitle-root {
    font-size: 1.2rem;
  }

  .MuiPaper-root {
    display: flex;
    align-items: center;
    padding: 30px 50px;
    overflow: scroll;
  }

  .MuiAutocomplete-popper {
    .MuiPaper-root {
      padding: 0 !important;
    }
  }

  .MuiDialogContent-root {
    text-align: center;
  }

  .profil-img {
    img {
      border-radius: 50%;
      width: 100px;
      height: 100px;
    }
  }
}

.popup-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .profil-img {
    img {
      width: 80px;
      height: 80px;
    }
  }

  .MuiDialogContent-root {
    width: 130px;
    padding: 10px 20px;
  }
}

@media (max-width: 500px) {
  .popup-content {
    flex-direction: column;
    max-width: 100%;
  }
}
