.content-quotas {
  display: flex;
  flex-direction: column;
  align-items: center;

  .buttons-quotas {
    .btn-newCollabs {
      width: 211px !important;
    }
  }

  .inputs-quotas {
    display: flex;
    flex-direction: row;
    column-gap: 70px;
    margin-bottom: 25px;

    .loader {
      height: inherit;
    }

    .MuiInputBase-root {
      width: 100px;
    }
  }
}

.buttons-quotas {
  display: flex;
  column-gap: 30px;
  justify-content: center;
}

.chips {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  li {
    width: auto;
    padding: 8px 8px 8px 0;
  }
}

@media screen and (max-width: 750px) {
  .content-quotas .buttons-quotas .btn-newCollabs {
    width: inherit !important;
  }
}

@media screen and (max-width: 450px) {
  .content-quotas {
    align-items: normal;
    width: 100%;

    .inputs-quotas {
      column-gap: 0;
      justify-content: space-between;
    }

    .buttons-quotas {
      flex-direction: column-reverse;
      row-gap: 10px;

      .btn-newCollabs {
        width: 100% !important;
      }
    }
  }
}
