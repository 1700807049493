@import 'variables';
@import 'width';
@import 'padding';
@import 'margin';
@import 'fonts';
@import 'form';
@import 'listVisualizationHeader';
@import 'DataInfos';
@import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: $background-white;
}

.bold {
  font-weight: 700;
}
.light {
  font-weight: 400;
  font-size: 20px;
}

.no-data {
  font-size: 16px;
  text-align: center;
  color: black;
}

iframe {
  border: none;
}

// ------------ COMPOSANTS ------------ //
@import 'src/components/pageLayout/PageLayout';
@import '../components/popup/PopUp/PopUp';

@import 'src/components/inputs/button/PrimaryButton';
@import 'src/components/inputs/customGroupRadios/CustomGroupRadios';
@import 'src/components/inputs/customSelect/CustomSelect';
@import 'src/components/inputs/customSelect/MultiCustomSelect';
@import 'src/components/inputs/searchBox/SearchBox';
@import 'src/components/inputs/checkBox/CheckBox';
@import 'src/components/inputs/uploader/Uploader';
@import '../components/popup/quotasPopUp/QuotasPopUp';
@import '../components/popup/interviewPopup/InterviewPopUp';
@import '../components/popup/interviewPopup/ReviewPopUp';
@import '../components/popup/messagePopUp/Publication';

@import 'src/components/holidayRequestApprobation/HolidayRequestApprobation';
@import 'src/components/stepper/Stepper';
@import 'src/components/subFormHeader/SubFormHeader';
@import 'src/components/filteredListItems/FilteredListItems';
@import 'src/components/listItemsFooter/ListItemsFooter';
@import 'src/components/navBar/NavBar';
@import 'src/components/navBar/placeFilter/PlaceFilter';
@import 'src/components/navBar/yearFilter/YearFilter';
@import 'src/components/profilImage/ProfilImage';
@import 'src/components/clientImage/ClientImage';
@import 'src/components/tabNavigation/TabNavigation';

// ------------ PAGES + SOUS COMPOSANTS ------------ //
@import 'src/pages/dashboard/boxMetric/BoxMetric';
@import 'src/pages/dashboard/Dashboard';
@import 'src/pages/dashboard/userBoxMetric/UserBoxMetric';

@import 'src/pages/addCollab/AddCollab';
@import 'src/pages/addCollab/form/CreationFormPartOne';
@import 'src/pages/addCollab/form/CreationFormPartTwo';

@import 'src/pages/listMaterials/ListMaterials';
@import 'src/pages/listCollabs/ListCollabsBodyTable';
@import 'src/pages/listClients/ListClientsBodyTable';

@import 'src/pages/profil/career/CareerInfos';
@import 'src/pages/profil/profilHeader/ProfilHeader';
@import 'src/pages/profil/dataInfos/services/ListServices';
@import 'src/pages/profil/dataInfos/material/MaterialInfos';
@import 'src/pages/profil/dataInfos/notifications/Notifications';
@import 'src/pages/profil/dataInfos/expenses/Expenses';

@import 'src/pages/setting/settingsForm/SettingsForm';
@import 'src/pages/setting/themeForm/themeForm';
@import 'src/pages/setting/Setting';

@import 'src/pages/myHolidays/MyHolidays';
@import 'src/pages/myHolidays/components/myRequests/myRequest/MyRequest';
@import 'src/pages/myHolidays/components/myRequests/MyRequests/';
@import 'src/pages/myHolidays/components/holidayRequest/HolidayRequest';
@import 'src/pages/myHolidays/pdfModal/PdfModal';
@import 'src/pages/holidaysRequests/HolidaysRequestsBodyTable';

@import 'src/pages/myExpenses/MyExpenses';

@import 'src/pages/expenses/Expenses';

@import 'src/pages/planning/planningModal/PlanningModal';
@import 'src/pages/planning/planningHeader/PlanningHeader';
@import 'src/pages/planning/planningLegend/PlanningLegend';
@import 'src/pages/planning/planningMonthOrWeekChanger/PlanningMonthOrWeekChanger';
@import 'src/pages/planning/planningYearDisplayer/PlanningYearDisplayer';
@import 'src/pages/planning/planningMonthDisplayer/PlanningMonthDisplayer';

@import 'src/pages/steamDej/SteamDej';

@import 'src/pages/group/Group';
@import 'src/pages/group/GroupPopUp';

@import 'src/pages/trombinoscope/TrombinoscopeCollab';
@import 'src/pages/trombinoscope/Trombinoscope';

@import 'src/pages/error404Page/Error404Page';
