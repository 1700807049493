.progress-number {
  color: $dark-grey;
}

.btn-box {
  width: 80%;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  margin-top: 30px;
}

.form-error {
  color: $error;
  margin-bottom: 15px;
  margin-top: 5px;
}

@media screen and (max-width: 1148px) {
  .btn-box {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .btn-box {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
}
