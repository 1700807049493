.box-accounts {
  .list-accounts {
    display: flex;
    align-items: center;
    column-gap: 100px;
    flex-wrap: wrap;
    row-gap: 30px;

    .box-account {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 60px;

      .will-be-created {
        color: $orange;
      }
    }
  }

  .data-title {
    padding-bottom: 15px;
  }

  p {
    margin: 0;
  }
}

.m-top {
  margin-top: 30px;
}

@media screen and (max-width: 800px) {
  .box-accounts .list-accounts {
    column-gap: 70px;
    row-gap: 20px;
  }
}
