.sub-header-planning {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  justify-content: flex-start;

  .filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 30px;
  }

  .legend-info-box {
    display: flex;
    align-items: center;
    column-gap: 10px;

    #infoIcon {
      color: $secondary-blue;
      cursor: pointer;
    }
  }

  .year-planning {
    display: flex;
    align-items: center;

    &.month-view,
    &.year-view {
      justify-content: space-between;
    }
  }
}

@media (max-width: 595px) {
  .header-planning {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .sub-header-planning {
      display: block;
      margin-left: 0;

      .search-box,
      .select {
        display: none;
      }
    }
  }
}
