.minPage-container {
  overflow-x: hidden;
  margin-right: 50px;
  margin-bottom: 85px;

  .maxWidth-page {
    max-width: 1300px;
    margin: 0 auto;
  }
}
.margin-left-open {
  margin-left: 305px;
}

.margin-left-close {
  margin-left: 90px;
}

@media screen and (max-width: 850px) {
  .margin-left-open,
  .margin-left-close {
    margin-left: 110px;
  }

  .minPage-container {
    margin-top: 25px;
    margin-right: 25px;
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 550px) {
  .margin-left-open,
  .margin-left-close {
    margin-left: 25px;
  }

  .minPage-container {
    margin-top: 5px;
  }
}
