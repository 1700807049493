.reporting-switch {
  .reporting-switch-toggle-box {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  .MuiSwitch-root {
    margin-left: -12px;
  }

  .loader {
    height: 100%;
  }
}
