.myholidays-content {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;

  .loader {
    height: 120px;
  }

  .myholidays-data {
    width: 35%;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }
  .my-holidays-requests-container {
    width: 63%;
  }
  .box-container-myholidays {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 28px;
    background-color: white;
    border-radius: 10px;

    .my-holiday-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 10px;

      .progress-number {
        color: $main-blue;
        font-weight: normal;
        font-size: 18px;
      }
    }
  }
  .box-myholidays-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .box-title-myholidays {
    font-weight: 600;
    margin: 0;
  }
  .box-content-myholidays {
    padding-top: 25px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 15px;
  }

  .myBalance-data {
    justify-content: left;
    column-gap: 35px;
  }

  .box-subcontent-myholidays {
    flex-direction: column;
  }
  .box-subtitle-myholidays {
    font-weight: 900;
    font-size: 14px;
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 1150px) {
  .myholidays-content {
    flex-direction: column;
    row-gap: 25px;

    .myholidays-data {
      flex-direction: row;
      column-gap: 25px;
      width: 100%;
    }

    .my-holidays-requests-container {
      width: 100%;
    }
  }
}

@media screen and (max-width: 750px) {
  .myholidays-content {
    .myholidays-data {
      flex-direction: column;
    }

    .box-container-myholidays {
      padding: 14px;

      .box-myholidays-header {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
