.containerTheme {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  padding-bottom: 25px;
  align-items: flex-end;

  .reset-theme-button {
    cursor: pointer;
    width: 250px;
    color: $dark-grey;
    font-size: 14px;

    &:hover {
      color: $main-blue;
    }
  }

  .hex-value {
    margin-top: -25px;
  }

  .primary-color-hex {
    color: $main-blue;
  }

  .secondary-color-hex {
    color: $secondary-blue;
  }

  .tertiary-color-hex {
    color: $tertiary-color;
  }
}
.title {
  font-size: 16px;
  font-weight: 500;
  color: $dark-grey;
  margin: 0;
}
.text-center {
  text-align: center;
}
.div-center {
  margin: auto;
}
