.delete-material-box {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .data-value {
    font-size: 14px;
  }

  .delete-material-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .column-gap {
      column-gap: 10px;
    }

    #delete-icon {
      cursor: pointer;
      color: $danger-red;
    }
  }
}
@media screen and (max-width: 800px) {
  .parentContainer {
    margin-top: 2%;
    margin-left: 50%;
    border: 1px solid lightgray;
    border-radius: 5px;
    max-height: 20vh;
    text-align: center;
    border-radius: 10px;
  }
}
