.expenses {
  .expenses-toggle-box {
    display: flex;
    align-items: flex-start;
    column-gap: 60px;
  }

  .switch-label {
    display: flex;
    align-items: center;
    column-gap: 15px;
    height: 50px;
  }

  .MuiSwitch-root {
    margin-left: -12px;
  }

  .loader {
    height: 100%;
  }

  .expenses-toggle-box {
    .MuiTextField-root {
      width: 110px !important;
    }
    .MuiOutlinedInput-notchedOutline {
      width: 100px !important;
    }
  }
}
