.refusal-holiday {
  text-align: left;

  .MuiDialog-paper {
    min-width: 50%;
  }

  .MuiTextField-root {
    width: 100%;
  }

  .MuiInputBase-multiline {
    height: 200px !important;
    width: 100%;

    // gestion scroll sur multiline textField
    .MuiInputBase-inputMultiline {
      padding-top: 0px !important;
      height: 180px !important;
      overflow: auto !important;
    }
  }
}
