.checkBox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 60px;

  .check-icon {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    img {
      width: 33px;
      height: 32px;
      object-fit: contain;
    }
    .checkbox {
      cursor: pointer;
      width: 16px;
    }
  }
}
