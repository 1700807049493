@import '/src/styles/variables.scss';

.check-icon-collab {
  padding-left: 20px;
  &.hover {
    cursor: text !important;
  }
}

.inactive-row {
  opacity: 0.7;
  background-color: $weak-opacity-blue;
}