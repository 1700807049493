.year-title-displayer {
  position: relative;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .year {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;

    .year-title {
      font-size: 22px;
      padding-right: 2%;
    }
  }

  .icon {
    color: $mid-grey;
    height: 25px;
    cursor: pointer;

    &:hover {
      color: $mid-dark-grey;
    }
  }

  .accentuated-title {
    font-size: 22px;
  }
}

.year-displayer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (min-width: 1145px) {
    flex-direction: column;
  }

  .month-part {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 595px) {
  .year-title-displayer {
    margin-top: 15px;
    justify-content: space-around;

    .year .year-title,
    .light {
      font-size: 18px;
    }
  }

  .year-displayer {
    overflow-y: scroll;
    height: 65vh;
  }
}
