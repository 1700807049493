@import url('https://fonts.googleapis.com/css2?family=Baumans&family=Montserrat');

$primary-font: 'Montserrat', sans-serif;
$secondary-font: 'Baumans', sans-serif;

* {
  font-family: $primary-font;
}

h1 {
  font-size: 50px;
  font-weight: 400;
  font-family: $secondary-font;
  margin: 0;
}

h2 {
  font-size: 34px;
  font-weight: 400;
  font-family: $secondary-font;
  margin: 0;
}

h3 {
  font-size: 27px;
  font-weight: 500;
  margin: 0 0 1.5rem;
}

h4 {
  font-size: 23px;
  font-weight: 500;
  margin: 0 0 2rem;
}

h5 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

p {
  font-size: 13px;
  font-weight: 500;
}

.accentuated-title {
  font-size: 30px;
  font-weight: 400;
  font-family: $primary-font;
  background: linear-gradient(90deg, $main-blue 20%, $tertiary-color 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.small-accentuated-title {
  font-size: 20px;
  font-weight: 1000;
  font-family: $primary-font;
  background: linear-gradient(90deg, $main-blue 20%, $tertiary-color 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-error {
  color: $error;
  font-size: 12px;

  p {
    margin: 0px;
  }
}

@media screen and (max-width: 450px) {
  .accentuated-title {
    font-size: 26px;
  }

  h3 {
    font-size: 24px;
  }

  .data h3 {
    padding-left: 0;
  }
}
