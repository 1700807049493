.popover {
  padding: 25px;

  .align-datePickers {
    padding-bottom: 15px;
  }

  .MuiInputBase-root,
  .MuiFormControl-root {
    width: 100%;
  }

  .select {
    padding-bottom: 15px;
  }
  .btn-container {
    padding-top: 8px;
    width: 100%;

    button {
      width: 100%;
    }
  }

  .holiday-error-box {
    p {
      margin: 0;
    }

    .my-holidays-link {
      cursor: pointer;
      color: $secondary-blue;
      text-decoration: none;
    }
  }
}
